<template>
	<div class="return-goods">
		<div class="my-title clearfix">
			<span class="">申请售后</span>
		</div>
		<div class="return-goods-con">
			<el-table :data="goods">

				<el-table-column prop="goods_name" label="商品"></el-table-column>
				<el-table-column prop="grade_goods_price" label="单价"  align="center" width="100">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.grade_goods_price > 0 ? scope.row.grade_goods_price : scope.row.goods_price }}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="max_refund_num" label="可退货数量" align="center" width="100">
                    <template slot-scope="scope">
                        <span>{{ getMaxRefundNum(scope.row) }}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="return_num" label="退货数量" align="center" width="200">
					<template slot-scope="scope">
						<el-input-number v-model="scope.row.apply_refund_num" :min="1" :max="getMaxRefundNum(scope.row)"></el-input-number>
					</template>
				</el-table-column>
				<el-table-column prop="return_warehouse" label="退货仓库" align="center" width="280">
					<template slot-scope="scope">
						<el-select v-model="scope.row.apply_warehouse_no" placeholder="请选择退货仓库">
						    <el-option
						      v-for="item in [{warehouse_no: '', warehouse_name: '不指定' }]
						        .concat(formData.type == RefundTypeEnum.RETURN.value ? scope.row.delivery : [])"
						      :key="item.warehouse_no"
						      :label="item.warehouse_name"
						      :value="item.warehouse_no">
						    </el-option>
						  </el-select>
					</template>
				</el-table-column>
			</el-table>
			<!-- <div class="title" v-for="(item, idx) in goods" :key="idx">
				<div class="right">
					<span>{{ item.goods_name }}</span>
					<p><em>单价：￥{{item.goods_price}}</em><em>数量：{{ item.total_num }}</em></p>
				</div>
			</div> -->
			<div class="return-goods-form">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
					<el-form-item label="服务类型:" prop="type">
						<el-radio-group v-model="formData.type" @change="resetApplyWarehouse">
							<el-radio :label="item.value" v-for="(item, index) in RefundTypeEnum.data" :key="index">
							{{item.name}}</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="申请原因:" prop="desc">
						<el-input :rows="4" type="textarea" v-model="formData.content" placeholder="请详细填写申请原因，注意保持商品的完好，建议您先与卖家沟通~"></el-input>
					</el-form-item>

					<el-form-item label="退款金额:" class="price" v-if="formData.type != RefundTypeEnum.EXCHANGE.value">
						<span class="orange">￥{{ getTotalPayPrice() }}</span>
					</el-form-item>

					<el-form-item label="上传凭证(最多6张):" prop="">
						<el-upload
						action="/index.php?s=/api/upload/image"
						list-type="picture-card" :auto-upload="true" :limit="6" :file-list="imageList"
						ref="upload" :headers="headers"
						:on-success="uploadSucc">
							<i slot="default" class="el-icon-plus"></i>
							<div slot="file" slot-scope="{file}">
								<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>
									<span v-if="!disabled" class="el-upload-list__item-delete"
										@click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>

					<el-form-item>
						<el-button class="btn-orange" type="warning" @click="handleSubmit()">确认提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { RefundTypeEnum, RefundStatusEnum } from '@/common/enum/order/refund'
	import * as UploadApi from '@/api/upload'
	import * as RefundApi from '@/api/refund'

	const maxImageLength = 6

	export default {
		data() {
			return {
				sumValue:0,
				headers: {
					"Access-Token": localStorage.getItem('token')
				},
				// 枚举类
				RefundTypeEnum,
        RefundStatusEnum,
				// 正在加载
				isLoading: true,
				// 订单商品id
				orderGoodsId: null,
				// 订单商品详情
				goods: {},
				// 表单数据
				formData: {
				  // 图片上传成功的文件ID集
				  images: [],
				  // 服务类型
				  type: 10,
				  // 申请原因
				  content: ''
				},
				// 用户选择的图片列表
				imageList: [],
				// 最大图片数量
				maxImageLength,

				priceShow:true,
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				rules: {
				}
			};
		},
		created() {
			if(this.$route.query.orderGoodsId){
				this.orderGoodsId =this.$route.query.orderGoodsId ? this.$route.query.orderGoodsId.split(',') : [];
			}
			// 获取订单商品详情
			this.getGoodsDetail()
		},
		methods: {
			// 获取订单商品详情
			getGoodsDetail() {
			  const app = this
			  app.isLoading = true
			  RefundApi.goods(app.orderGoodsId)
			    .then(result => {
					// console.log(result.data.goods)
                    let goods = result.data.goods
                    goods.forEach(item => {
                        item.apply_refund_num = 1
                        item.apply_warehouse_no = ''
                    })
                    this.goods = goods
			      app.isLoading = false
			    })
			},

			// 切换类型
			onSwitchService(value) {
			  this.formData.type = value
			},
			// 表单提交
			handleSubmit() {
			  const app = this
			  const { imageList } = app
			  // 判断是否重复提交
			  if (app.disabled === true) return false
			  // 按钮禁用
			  app.disabled = true
			  // 判断是否需要上传图片
			  if (imageList.length > 0) {
			    app.uploadFile()
			      .then(() => app.onSubmit())
			      .catch(err => {
			        if (err.statusCode !== 0) {
					  app.$message.error(err.errMsg)
			        }
			        console.log('err', err)
			      })
			  } else {
			    app.onSubmit()
			  }
			},

			// 提交到后端
			onSubmit() {
			  const app = this
				console.log(app.orderGoodsId)
                let orderGoods = []
                app.goods.forEach(e => {
                    orderGoods.push({
                        order_goods_id: e.order_goods_id,
                        apply_refund_num: e.apply_refund_num,
                        apply_warehouse_no: e.apply_warehouse_no
                    })
                })
			  RefundApi.apply(orderGoods, app.formData)
			    .then(result => {
				  app.$message.success(result.message)
				  app.$router.push('/myhome/returnlist')
			    })
			},

			// 上传图片
			uploadFile() {
			  const app = this
			  const { imageList } = app
			  // 批量上传
			  return new Promise((resolve, reject) => {
			    if (imageList.length > 0) {
			      UploadApi.image(imageList)
			        .then(fileIds => {
			          app.formData.images = fileIds
			          resolve(fileIds)
			        })
			        .catch(err => reject(err))
			    } else {
			      resolve()
			    }
			  })
			},
			handleRemove(file) {
				console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
				console.log(this.dialogImageUrl)
			},
			uploadSucc(response, file, fileList){
				let app = this
				console.log(fileList)
				app.formData.images = []
				fileList.forEach(item => {
				 	app.formData.images.push(parseInt(item.response.data.fileInfo.file_id))
				})
			},
            getMaxRefundNum (goods) {
        const refundIds = goods.orderM.refund.filter(e => e.status == RefundStatusEnum.NORMAL.value ||
    e.status == RefundStatusEnum.COMPLETED.value).map(e => e.order_refund_id)
                const refundGoodsList = goods.refund.filter(e => refundIds.indexOf(e.order_refund_id) > -1)
                if (goods.apply_warehouse_no) {
                    const delivery_num = goods.delivery.filter(e => e.warehouse_no === goods.apply_warehouse_no)
                        .reduce((sum, e) => sum + e.num, 0)
                    const refund_num = refundGoodsList.filter(e => e.warehouse_no === goods.apply_warehouse_no)
                        .reduce((sum, e) => sum + e.refund_num, 0)
                    return delivery_num - refund_num
                } else {
                    // search all refund num by warehouse_no is null
                    const refund_num = refundGoodsList.reduce((sum, e) => sum + e.refund_num, 0)
                    return goods.total_num - refund_num
                }
            },
            getTotalPayPrice () {
                return this.goods.reduce((sum, e) => sum + Number((e.grade_goods_price > 0 ? e.grade_goods_price : e.goods_price) * e.apply_refund_num), 0).toFixed(2)
            },
            resetApplyWarehouse() {
                this.goods.forEach(e => {
                    e.apply_warehouse_no = ''
                })
            }
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/return-goods.scss"
</style>
